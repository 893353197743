import React from 'react';
import 'animate.css'; // Import animate.css
import '../assets/css/Footer.css';

const Footer = () => {
  return (
    <footer className="footer-section animate__animated animate__fadeInUp"> {/* Animasi fadeInUp pada footer */}
      <div className="container">
        <div className="row justify-content-between align-items-center">
          {/* Copyright */}
          <div className="col-md-6">
            <p className="text-muted mb-0 animate__animated animate__fadeInLeft"> {/* Animasi fadeInLeft */}
              &copy; {new Date().getFullYear()} All Rights Reserved. Managed by <a href="#home">Azyqs</a>. 
            </p>
          </div>

          {/* Social Media Links */}
          <div className="col-md-6 text-md-right text-center">
            <a
              href="https://wa.me/+6287741681490"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link animate__animated animate__fadeIn" // Animasi fadeIn
            >
              <i className="fab fa-whatsapp"></i>
            </a>
            <a
              href="https://www.instagram.com/al.azyqs"
              target="_blank"
              rel="noopener noreferrer"
              className="social-link animate__animated animate__fadeIn" // Animasi fadeIn dengan delay
            >
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
