import React, { useState } from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert2
import 'animate.css'; // Import animate.css
import contactPic from '../assets/images/contactPic.png';
import '../assets/css/Contact.css'; // Menghubungkan CSS Contact Section

const Contact = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);

  // Fungsi untuk menampilkan error jika input tidak valid
  const showError = (errorMessage) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: errorMessage,
      confirmButtonText: 'OK',
      confirmButtonColor: '#007bff',
    });
  };

  // Fungsi untuk menampilkan notifikasi sukses setelah pesan dikirim
  const showSuccess = (method) => {
    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: `Your message has been sent via ${method}.`,
      confirmButtonText: 'OK',
      confirmButtonColor: '#007bff',
    });
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!email || !message) {
      showError('Please fill in both your email and message!'); // Notifikasi error jika input tidak lengkap
      return;
    }

    // Proses pengiriman email
    window.location.href = `mailto:server@azyqs.my.id?subject=Contact%20from%20${email}&body=${message}`;
    
    // Tampilkan SweetAlert2 sukses
    showSuccess('Email');
  };

  const handleWhatsAppSubmit = (e) => {
    e.preventDefault();
    if (!message) {
      showError('Please enter your message before sending via WhatsApp!'); // Notifikasi error jika input tidak lengkap
      return;
    }

    // Proses pengiriman pesan WhatsApp
    const whatsappUrl = `https://wa.me/+6287741681490?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, '_blank');

    // Tampilkan SweetAlert2 sukses
    showSuccess('WhatsApp');
  };

  return (
    <section id='contact' className="contact-section">
      <div className="container">
        <img src={contactPic} alt="Illustration" className="img-fluid" />
        <form className="contact-form">
          <h2>Contact Me to Contribute!</h2>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">Message</label>
            <textarea
              className="form-control"
              id="message"
              value={message}
              onChange={handleMessageChange}
              placeholder="Enter your message"
              rows="5"
              required
            ></textarea>
          </div>
          <button onClick={handleEmailSubmit} className="btn btn-primary">
            Send via Email
          </button>
          <button onClick={handleWhatsAppSubmit} className="btn btn-success">
            Send via WhatsApp
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
