import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header'; 
import Footer from '../components/Footer';

const NotFound = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(5); // Mulai dari 5 detik

  useEffect(() => {
    // Timer untuk hitung mundur
    const countdownTimer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect otomatis ke homepage setelah countdown selesai
    const timer = setTimeout(() => {
      navigate('/');
    }, 5000);

    // Cleanup timer jika komponen di-unmount
    return () => {
      clearInterval(countdownTimer); // Bersihkan countdown timer
      clearTimeout(timer); // Bersihkan redirect timer
    };
  }, [navigate]);

  return (
    <div>
      <Header />
      <section
        className="not-found-section"
        style={{
          backgroundColor: '#1b1b1b',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
          textAlign: 'center'
        }}
      >
        <div>
          <h1 className="display-1">404</h1>
          <p className="lead">Oops! The page you're looking for doesn't exist.</p>
          <p className="lead">
            Redirecting to the homepage in {countdown} second{countdown > 1 ? 's' : ''}.
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default NotFound;
