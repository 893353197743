import React from 'react';
import 'animate.css'; // Import animate.css
import '../assets/css/About.css'
import aboutPic from '../assets/images/aboutPic.png'; 

const About = () => {
  return (
    <section id='about'
      className="about-section"
      style={{
        backgroundColor: '#292b2c', // Warna background yang sedikit lebih terang
        color: '#f1f1f1', // Warna teks yang sedikit terang untuk kontras
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 20px'
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            {/* Animasi pada gambar */}
            <img
              src={aboutPic} // Gambar placeholder
              alt="About Illustration"
              className="animate__animated animate__fadeInLeft animate__delay-1s" // Animasi fadeInLeft dengan delay
              style={{ width: '80%', borderRadius: '8px' }}
            />
          </div>
          <div className="col-md-6 d-flex flex-column justify-content-center">
            <h2 className="display-4 animate__animated animate__fadeInRight animate__delay-2s"> {/* Animasi fadeInRight */}
              About Me
            </h2>
            <p className="lead animate__animated animate__fadeInRight animate__delay-2s"> {/* Animasi fadeInRight dengan delay */}
            I’m a passionate learner with a deep interest in IT and various fields of knowledge. 
            I enjoy exploring and experimenting with new projects,
            always eager to expand my skills and dive into new challenges in the world of technology and beyond.
            </p>
            <a 
              href="#projects" 
              className="btn btn-outline-light btn-lg mt-3 animate__animated animate__fadeIn animate__delay-3s" // Animasi fadeIn pada tombol
            >
              See My Projects
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
