import React from 'react';
import Swal from 'sweetalert2'; // Import SweetAlert2
import '../assets/css/Projects.css'; // Import file CSS

const Projects = () => {
  // Fungsi untuk menampilkan SweetAlert ketika project diklik
  const handleProjectClick = (projectName) => {
    Swal.fire({
      icon: 'info',
      title: `${projectName} - Coming Soon!`,
      text: 'This project is still under development. Stay tuned for updates!',
      confirmButtonText: 'OK',
      confirmButtonColor: '#007bff',
    });
  };

  return (
    <section id='projects' className="project-section">
      <h2>My Projects</h2>
      <div className="project-container">
        <div className="project-card" onClick={() => handleProjectClick('Project 1')}>
          <img src="https://via.placeholder.com/300x200" alt="Project 1" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">Project 1</h3>
            <p className="card-text">
              This is a short description of the project. It's focused on solving a specific problem.
            </p>
          </div>
        </div>

        <div className="project-card" onClick={() => handleProjectClick('Project 2')}>
          <img src="https://via.placeholder.com/300x200" alt="Project 2" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">Project 2</h3>
            <p className="card-text">
              This project focuses on delivering user-friendly designs and interfaces.
            </p>
          </div>
        </div>

        <div className="project-card" onClick={() => handleProjectClick('Project 3')}>
          <img src="https://via.placeholder.com/300x200" alt="Project 3" className="img-fluid"/>
          <div className="card-body">
            <h3 className="card-title">Project 3</h3>
            <p className="card-text">
              This project emphasizes speed and performance with minimal loading times.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
