import React from 'react';
import 'animate.css'; // Import animate.css
import '../assets/css/Home.css'
import homePic from '../assets/images/homePic.png'; // Import gambar

const Home = () => {
  return (
    <section id='home'
      className="home-section animate__animated animate__fadeIn" // Animasi fadeIn untuk seluruh section
      style={{
        backgroundColor: '#1b1b1b', // Warna background gelap
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        paddingTop: '100px', // Tambahkan lebih banyak padding untuk menghindari header
      }}
    >
      <div className="container">
        <div className="row align-items-center"> {/* Atur elemen dalam flexbox atau grid */}
          <div className="col-md-6 text-center text-md-start"> {/* Text di layar kecil di tengah */}
            <h1 className="display-4 animate__animated animate__fadeInDown animate__delay-1s"> {/* Animasi fadeInDown dengan delay */}
              Who am I?
            </h1>
            <p className="lead animate__animated animate__fadeInUp animate__delay-1s"> {/* Animasi fadeInUp dengan delay */}
              My Name is Faeza, anonymously known as Azyqs. I'm a junior developer and programmer,
              currently learning various IT skills while attending high school.
              I'm passionate about exploring tech, creating cool projects,
              and always up for learning something new in the world of programming and development.
            </p>
            <a 
              href="#about" 
              className="btn btn-outline-primary btn-lg mt-3 animate__animated animate__fadeInUp animate__delay-1s" // Animasi bounce pada tombol
            >Want to Know More?</a>
          </div>
          <div className="col-md-6 text-center"> {/* Gambar di tengah pada layar kecil */}
            <img 
              src={homePic} 
              alt="Illustration" 
              className="img-fluid animate__animated animate__fadeInRight animate__delay-1s"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
